import { Container, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router";
import Page from "../../../components/layouts/Page";
import theme from "../../../theme";
import sanefa from "../../../assets/img/habitacions/sanefa.svg";
import bg from "../../../assets/img/fons-2.jpg";
import CustomButton from "../../../components/elements/CustomButton";
import { CartesHotel } from "../../../components/elements/CartesHotel";
import hotelImg from "../../../assets/img/hotel/hotel-logo.jpg";
import barImg from "../../../assets/img/hotel/bar-1.jpg";
import restaurantImg from "../../../assets/img/restaurant/Restaurant_1_v2.jpg";
import saloImg from "../../../assets/img/hotel/salo-1.jpg";
import habitacionsImg from "../../../assets/img/hotel/habitacions-1.jpg";
import circImg from "../../../assets/img/hotel/circ.jpg";

const imatges = [{ foto: hotelImg }, { foto: habitacionsImg }, { foto: barImg }, { foto: restaurantImg }, { foto: saloImg }];

function Hotel() {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<Page title="Insomnia Corporation - Hotel">
			<Box className={classes.portada}>
				<Container maxWidth="xl">
					<Grid spacing={8} container>
						<Grid item md={6} xs={12} mb={5}>
							{matches && <CartesHotel imatges={imatges} />}
						</Grid>

						<Grid item md={6} xs={12} zIndex={10}>
							{!matches && (
								<Box className={classes.wrapImg} my={4}>
									<img src={hotelImg} alt={"Insomnia Hotel"} className={classes.img} />
								</Box>
							)}
							<Box>
								<Typography variant="h1" pb={3} fontSize={45} color={theme.color.secondary}>
									Insomnia
									<br /> Hotel
								</Typography>
								<Divider
									sx={{
										borderColor: theme.color.secondary,
									}}
								/>
							</Box>
							<Typography pt={3} variant="body1" color={theme.color.secondary}>
								¡Bienvenidos al hotel temático de terror más escalofriante que jamás hayas experimentado! Sumérgete en un mundo de misterio y
								suspenso mientras te adentras en las profundidades de cada habitación única, diseñada como una intrigante escape room. En este
								enigmático establecimiento, las emociones y la adrenalina fluyen libremente mientras te enfrentas a desafíos y rompecabezas que
								pondrán a prueba tu ingenio y valentía.
							</Typography>
							<Typography py={2} variant="body1" color={theme.color.secondary}>
								¿Serás capaz de encontrar todas las comodidades de tu habitación?
							</Typography>

							<Typography py={2} variant="body1" color={theme.color.secondary}>
								Cada habitación del hotel te transporta a un escenario totalmente diferente, donde los detalles macabros y la atmósfera lúgubre
								te mantienen alerta en todo momento. La decoración y los efectos especiales te sumergen aún más en la experiencia, haciendo que
								te sientas como si estuvieras viviendo una película de terror.
							</Typography>
							<Typography py={2} variant="body1" color={theme.color.secondary}>
								¡Reserva tu estancia y prepárate para enfrentar tus miedos más profundos en un entorno de terror como ningún otro!
							</Typography>
							<Typography py={2} variant="body1" color={theme.color.secondary}>
								Durante los meses de verano, Julio y Agosto, entrar al hotel es exclusivo a los huéspedes que se alojan con nosotros.
							</Typography>

							<Typography py={2} variant="body1" color={theme.color.secondary}>
								<small>*En Insomnia Hotel, no hay personajes que te asusten.</small>
							</Typography>
							<Typography py={1} variant="body1" color={theme.color.secondary}>
								Disponemos de distintos espacios para disfrutar:
							</Typography>
							<Box my={15}>
								<Divider
									sx={{
										background: `url(${sanefa})`,
										borderColor: "transparent",
										height: 15,
										backgroundRepeat: "repeat-x",
									}}
								/>
							</Box>
							<Box my={15}>
								{!matches && (
									<Box className={classes.wrapImg} my={4}>
										<img src={habitacionsImg} alt={"Insomnia Hotel"} className={classes.img} />
									</Box>
								)}
								<Typography variant="h3" pb={3} fontSize={35} color={theme.color.secondary}>
									Habitaciones
								</Typography>
								<Divider
									sx={{
										borderColor: theme.color.secondary,
									}}
								/>
								<Typography py={3} variant="body1" color={theme.color.secondary}>
									En Insomnia Hotel actualmente disponemos de 4 habitaciones temáticas con un escape room diferente en cada una de ellas.
								</Typography>
								<CustomButton light title={"+ info"} onClick={() => navigate("/habitacions")} />
							</Box>
							<Box my={15}>
								<Divider
									sx={{
										background: `url(${sanefa})`,
										borderColor: "transparent",
										height: 15,
										backgroundRepeat: "repeat-x",
									}}
								/>
							</Box>
							<Box my={15}>
								{!matches && (
									<Box className={classes.wrapImg2} my={4}>
										<img src={barImg} alt={"Insomnia Hotel"} className={classes.img} />
									</Box>
								)}
								<Typography variant="h3" pb={3} fontSize={35} color={theme.color.secondary}>
									Doctor's museum bar
								</Typography>
								<Divider
									sx={{
										borderColor: theme.color.secondary,
									}}
								/>
								<Typography py={3} variant="body1" color={theme.color.secondary}>
									Un espacio tematizado con las obras de arte del Doctor, donde tomar un fantástico cóctel mientras juegas al billar gratuito
									o pruebas uno de los 3000 juegos arcade de nuestra máquina recreativa.
								</Typography>
							</Box>
							<Box my={15}>
								<Divider
									sx={{
										background: `url(${sanefa})`,
										borderColor: "transparent",
										height: 15,
										backgroundRepeat: "repeat-x",
									}}
								/>
							</Box>
							<Box>
								{!matches && (
									<Box className={classes.wrapImg} my={4}>
										<img src={restaurantImg} alt={"Insomnia Hotel"} className={classes.img} />
									</Box>
								)}
								<Typography variant="h3" pb={3} fontSize={35} color={theme.color.secondary}>
									Restaurante “K”
								</Typography>
								<Divider
									sx={{
										borderColor: theme.color.secondary,
									}}
								/>
								<Typography py={3} variant="body1" color={theme.color.secondary}>
									Un restaurante secreto donde comer los exquisitos platos de la familia Krugger. Para encontrar la carta de nuestro
									restaurante, tendréis que exprimir vuestros cerebros. No olvidéis mirar el calendario para ver los eventos especiales que
									ofrecemos en nuestro restaurante.
								</Typography>
								<CustomButton light title={"+ info"} onClick={() => navigate("/restaurant")} />
							</Box>
							<Box my={15}>
								<Divider
									sx={{
										background: `url(${sanefa})`,
										borderColor: "transparent",
										height: 15,
										backgroundRepeat: "repeat-x",
									}}
								/>
							</Box>
							<Box>
								{!matches && (
									<Box className={classes.wrapImg2} my={4}>
										<img src={saloImg} alt={"Insomnia Hotel"} className={classes.img} />
									</Box>
								)}
								<Typography variant="h3" pb={3} fontSize={35} color={theme.color.secondary}>
									Salón
								</Typography>
								<Divider
									sx={{
										borderColor: theme.color.secondary,
									}}
								/>
								<Typography py={3} variant="body1" color={theme.color.secondary}>
									Un espacio ambientado en los años 20 donde relajarse mientras disfrutas de un té o un café. Prueba las escape box, cajas de
									madera inspiradas en escape room para poner a prueba tu cerebro.
								</Typography>
							</Box>

							<Box mt={15} mb={40}>
								<Divider
									sx={{
										background: `url(${sanefa})`,
										borderColor: "transparent",
										height: 15,
										backgroundRepeat: "repeat-x",
									}}
								/>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</Page>
	);
}

export default Hotel;

const useStyles = makeStyles((theme) => ({
	portada: {
		paddingTop: 230,
		height: "100%",
		background: `url(${bg})`,
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		paddingBottom: 230,
		[theme.breakpoints.down("sm")]: {
			paddingTop: 40,
		},
	},
	wrapImg: {
		transform: "rotate(-5deg)",
		transition: "0.2s",
		zIndex: 0,
		"&:hover": {
			transform: "rotate(5deg)",
		},
		textAlign: "center",
	},

	wrapImg2: {
		transform: "rotate(5deg)",
		transition: "0.2s",
		zIndex: 0,
		"&:hover": {
			transform: "rotate(-5deg)",
		},
		textAlign: "center",
	},
	img: {
		zIndex: 0,
		width: "90%",
		border: "4px solid " + theme.color.secondary,
	},
}));
