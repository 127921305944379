import { Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { ReactComponent as Icon } from "../../../../assets/logoK.svg";
import bg from "../../../../assets/img/fons.jpg";
import Page from "../../../../components/layouts/Page";
import { getElements } from "../../../../API/API";
import Loading from "../../../../components/layouts/Loading";
import LangSelector from "../../../../lang/LangSelector";
import { useTranslation } from "react-i18next";

function Carta() {
	const classes = useStyles();
	const [cartes, setCartes] = useState([]);
	const [loading, setLoading] = useState(true);
	const { t, i18n } = useTranslation();

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElements("carta_categories");
			setCartes(result);
			setLoading(false);
		};
		obtenir();
	}, []);

	return (
		<Page>
			<Box className={classes.portada}>
				<Container>
					<Box>
						<Box
							pt={10}
							display="flex"
							flexDirection={"column"}
							alignItems="center"
						>
							<Icon />
							<Box my={2} />
							<LangSelector />
						</Box>
						<Loading loading={loading}>
							<Box>
								{/* <Box mt={8} mb={5} className={classes.seccio}>
									<Typography variant="h3" color="white">
										{t("menu.hostes")}
									</Typography>
								</Box>
								<Linia
									titol={t("menu.primers")}
									subtitol={t("menu.braves") + " | Nachos"}
								/>
								<Linia
									titol={t("menu.segons")}
									subtitol={t("menu.escollir")}
								/> */}
								{cartes?.map((carta) => (
									<Box>
										<Box
											mt={8}
											mb={5}
											className={classes.seccio}
										>
											<Typography
												variant="h3"
												color="white"
											>
												{i18n.language === "ca"
													? carta?.nom
													: i18n.language === "es"
													? carta?.nom_es
													: carta?.nom_en}
											</Typography>
										</Box>
										{carta.text_prev && (
											<Box
												style={{
													border: "1px dashed #fff",
												}}
												p={2}
											>
												<Typography variant="caption">
													{i18n.language === "ca"
														? carta?.text_prev
														: i18n.language === "es"
														? carta?.text_prev_es
														: carta?.text_prev_en}
												</Typography>
												{carta.suplement && (
													<Typography color="white">
														+ {carta.suplement} €
													</Typography>
												)}
											</Box>
										)}
										{carta?.cartes?.map((linia) => (
											<Linia
												titol={
													i18n.language === "ca"
														? linia.titol
														: i18n.language === "es"
														? linia.titol_es
														: linia.titol_en
												}
												subtitol={
													i18n.language === "ca"
														? linia?.subtitol
														: i18n.language === "es"
														? linia?.subtitol_es
														: linia?.subtitol_en
												}
												preu={linia.preu}
											/>
										))}
										{carta.text_post && (
											<Typography
												color="white"
												style={{ fontSize: 13 }}
												mt={2}
											>
												{i18n.language === "ca"
													? carta?.text_post
													: i18n.language === "es"
													? carta?.text_post_es
													: carta?.text_post_en}
											</Typography>
										)}
									</Box>
								))}
							</Box>
						</Loading>
					</Box>
				</Container>
			</Box>
		</Page>
	);
}

export default Carta;

function Linia({ titol, subtitol, preu, np }) {
	const classes = useStyles();
	return (
		<Box className={classes.linia} my={np ? 0 : 4}>
			<Box className={classes.titol}>
				<Typography variant="body1" color={"white"}>
					{titol}
				</Typography>
				<Typography
					variant="caption"
					style={{ whiteSpace: "pre-line" }}
				>
					{subtitol}
				</Typography>
			</Box>
			<Box width={150} textAlign={"right"}>
				<Typography variant="body1" color={"white"}>
					{preu}
				</Typography>
			</Box>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	portada: {
		background: `url(${bg})`,
		backgroundSize: "auto",
		backgroundPosition: "top",
		// paddingTop: 100,
		paddingBottom: 100,
	},
	linia: {
		display: "flex",
		justifyContent: "space-between",
	},
	titol: {
		textAlign: "left",
	},
	dash: {
		marginTop: 20,
		marginBottom: 20,
	},
	seccio: {
		borderBottom: "2px solid #fff",
		paddingBottom: 10,
	},
}));
