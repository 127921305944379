import { Circle } from "@mui/icons-material";
import { Box, Collapse, Grid, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import Badge from "../../../../components/elements/Badge";
import LoadingOverlay from "../../../../components/layouts/LoadingOverlay";
import { esJoc, quinJoc, teJoc } from "../../../../components/Utils";
import theme from "../../../../theme";
import { getDate } from "date-fns";
import { es } from "date-fns/locale";
import { useEffect, useState } from "react";
import { getElement, getElements } from "../../../../API/API";
import { IconJoc, JocCalendari, JocLlegenda } from "../../../../components/elements/JocLlegenda";
import calStyle from "../../../../theme/calStyle.css";

function DialogReservaCalendariHotel({
	data,
	dataJoc,
	habitacio,
	setHabitacio,
	loading,
	habitacions,
	setJugadors,
	setData,
	jugadors,
	select,
	setLoading,
	setPreu,
	admin,
}) {
	const matches = useMediaQuery("(min-width:960px)");
	const [exclude, setExclude] = useState([]);
	const [persones, setPersones] = useState();
	const [jocs, setJocs] = useState([]);

	useEffect(() => {
		const obtenir = async () => {
			setLoading(true);
			const { result } = await getElements("jocs");
			setJocs(result);
			setLoading(false);
		};
		obtenir();
	}, []);

	useEffect(() => {
		const obtenir = async () => {
			setLoading(true);
			const { result } = await getElement("getOcupatsScapes", habitacio?.id);
			console.log(result);
			let ex = [];
			Object.entries(result).forEach(([key, value]) => {
				ex.push(moment(key).toDate());
			});
			setTimeout(() => {
				setExclude(ex);
				setLoading(false);
			}, 1000);
		};
		if (habitacio?.id) obtenir();

		setJugadors();
		let result = [];
		for (let index = habitacio?.habitacio.min_p; index <= habitacio?.habitacio.max_p; index++) {
			result.push({ persones: index, text: index + " personas" });
		}
		setPersones(result);
		setData();
	}, [habitacio]);

	useEffect(() => {
		if (jugadors) {
			const preu = habitacio?.preus?.filter((item) => Number(item?.persones) === jugadors && item?.joc === (esJoc(data, dataJoc) ? "1" : "0"))[0]?.preu;
			setPreu(preu);
		} else {
			setPreu("-");
		}
	}, [jugadors, select, data]);

	const renderDia = (day, date) => {
		let add;
		if (teJoc(moment(date), dataJoc)) {
			const joc = quinJoc(moment(date), dataJoc);
			add = <JocCalendari joc={joc} />;
		}
		return (
			<Box style={{ position: "relative" }}>
				<span>
					{getDate(date)} {add}
				</span>
			</Box>
		);
	};

	function disabledDate(current) {
		return habitacio?.habitacio.dies
			?.split(",")
			.map((n) => (parseInt(n, 10) + 1 === 7 ? 0 : parseInt(n, 10) + 1))
			?.some((dia) => dia === moment(current).day());
	}

	const renderEspecial = () => {
		const joc = quinJoc(moment(data), dataJoc);
		const Icon = IconJoc(joc?.icon);
		return joc ? (
			<Box display="flex">
				<Box>
					<Icon fill={joc?.color} style={{ width: 40 }} />
				</Box>
				<Typography ml={1} variant="caption" color="black">
					<strong>{joc?.nom}</strong>: {joc?.desc}
				</Typography>
			</Box>
		) : (
			<Box display="flex">
				<Typography variant="caption" color="black">
					{data && "Recuerda que este día no hay ningún especial ni terror."}
				</Typography>
			</Box>
		);
	};

	return (
		<LoadingOverlay loading={loading}>
			<Grid spacing={matches ? (admin ? 2 : 10) : 0} container>
				<Grid item md={5} mt={7} xs={12} mb={4}>
					{!admin && (
						<div>
							<Typography variant="h6" mb={2} fontSize={22}>
								Seleccione habitación
							</Typography>
							<Grid container spacing={matches ? 4 : 1}>
								{habitacions?.map((item, index) => (
									<Grid item xs={4}>
										<Badge text={item?.nom} index={item} set={setHabitacio} select={habitacio} foto={item?.foto} />
									</Grid>
								))}
							</Grid>
						</div>
					)}
					{habitacio && (
						<Collapse in={habitacio}>
							<Box my={matches ? 0 : 5}>
								<Typography variant="h6" my={2} fontSize={22}>
									Seleccione número de personas
								</Typography>
								<Grid container spacing={4}>
									{persones?.map((item) => (
										<Grid item xs={4}>
											<Badge text={item.text} index={Number(item.persones)} set={setJugadors} select={jugadors} />
										</Grid>
									))}
								</Grid>
							</Box>
						</Collapse>
					)}
				</Grid>
				<Grid item md={7} my={matches ? 2 : 0} xs={12}>
					<Collapse in={habitacio && jugadors}>
						<>
							<ReactDatePicker
								onChange={(data) => setData(data)}
								selected={data ?? ""}
								locale={es}
								dateFormat="dd/MM/yyyy"
								renderDayContents={renderDia}
								inline
								minDate={admin ? moment().toDate() : moment().add(2, "days").toDate()}
								maxDate={!admin && moment().add(2, "months").toDate()}
								excludeDates={!admin && exclude}
								filterDate={!admin && disabledDate}
								style={calStyle}
							/>
							<Box display={matches ? "flex" : "block"} flexWrap="wrap" justifyContent="space-between" mb={2}>
								{jocs?.map((item) => (
									<JocLlegenda item={item} />
								))}
							</Box>
						</>
					</Collapse>
				</Grid>
			</Grid>
			{renderEspecial()}
		</LoadingOverlay>
	);
}

export default DialogReservaCalendariHotel;
