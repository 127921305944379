import { Container, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { getElements } from "../../../API/API";
import Page from "../../../components/layouts/Page";
import bg from "../../../assets/img/fons-2.jpg";
import Loading from "../../../components/layouts/Loading";
import theme from "../../../theme";
import { Cartes } from "../../../components/elements/Cartes";
import img1 from "../../../assets/img/restaurant/Restaurant_1_v2.jpg";
import img2 from "../../../assets/img/restaurant/Restaurant_2_v1.jpg";
import img3 from "../../../assets/img/restaurant/r-croquetes-ceps.jpg";
import img4 from "../../../assets/img/restaurant/r-fajitas.jpg";
import moment from "moment";
import { quinJoc, teJoc, tePleRestaurant } from "../../../components/Utils";
import { JocCalendari } from "../../../components/elements/JocLlegenda";
import { getDate } from "date-fns";
import ReactDatePicker from "react-datepicker";
import { es } from "date-fns/locale";
import paper from "../../../assets/img/paper-popup.png";
import sanefa from "../../../assets/img/habitacions/sanefa.svg";
import { JocItemPublic } from "../../../components/elements/JocItemPublic";
import { Close } from "@mui/icons-material";

const imatges = [
	{
		foto: img1,
	},
	{
		foto: img2,
	},
	{
		foto: img3,
	},
	{
		foto: img4,
	},
];

function Restaurant() {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();
	const [jocs, setJocs] = useState([]);
	const [dataJoc, setDataJoc] = useState([]);
	const [pleRestaruant, setPleRestaruant] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const obtenir = async () => {
			setLoading(true);
			const { result } = await getElements("calendariPublic");

			setJocs(result.jocs);
			setDataJoc(result.dataJoc);
			setPleRestaruant(result.pleRestaruant);
			setLoading(false);
		};
		obtenir();
	}, []);

	const renderDia = (day, date) => {
		let add;
		let res;
		if (teJoc(moment(date), dataJoc)) {
			const joc = quinJoc(moment(date), dataJoc);
			add = <JocCalendari joc={joc} />;
		}
		if (tePleRestaurant(moment(date), pleRestaruant)) {
			res = (
				<Close
					style={{
						color: theme.color.primary,
						opacity: 0.8,
					}}
				/>
			);
		}
		return (
			<Box style={{ position: "relative" }}>
				<span>
					{getDate(date)} {add}
				</span>
				<Box position={"absolute"} display="flex" alignItems={"center"} justifyContent="center" top={0} width={"100%"} height={"100%"}>
					{res}
				</Box>
			</Box>
		);
	};

	return (
		<Page title="Insomnia Corporation - Restaurante">
			<Box className={classes.portada}>
				<Container maxWidth="xl">
					<Loading loading={loading}>
						<Grid spacing={8} container>
							<Grid item md={6} xs={12}>
								{matches ? (
									<Cartes imatges={imatges} />
								) : (
									<Box className={classes.wrapImg}>
										<img src={imatges[0].foto} alt="Restaurant" className={classes.img} />
									</Box>
								)}
							</Grid>
							<Grid item md={6} xs={12} zIndex={10}>
								<Typography variant="h3" fontSize={30} color={theme.palette.secondary.main} pb={2}>
									Restaurante “K” en Insomnia Hotel
								</Typography>
								<Divider sx={{ borderColor: theme.color.secondary }} />
								<Box py={3}>
									<Typography py={1} variant="body1" color={theme.color.secondary}>
										El restaurante de la familia Krugger, restaurante “K”, es un restaurante secreto donde podréis degustar los exquisitos
										platos de la familia Krugger. Es un lugar perfecto para cenar con los amigos o la familia. Encontraréis desde exquisitas
										tapas, hasta nuestras deliciosas hamburguesas, todos los platos con una presentación única.
									</Typography>

									<Typography py={1} variant="body1" color={theme.color.secondary}>
										Para encontrar la carta de nuestro restaurante, tendréis que exprimir vuestros cerebros.
									</Typography>
									<Typography py={1} variant="body1" color={theme.color.secondary}>
										No olvidéis mirar el calendario para ver los eventos especiales que ofrecemos en nuestro restaurante.
									</Typography>
									<Typography py={1} variant="body1" color={theme.color.secondary}>
										Para reservar al restaurante, llama al:{" "}
										<a
											href="tel:+34689153847"
											style={{
												color: theme.palette.secondary.main,
											}}
										>
											689 153 847
										</a>
									</Typography>
								</Box>
								<Divider sx={{ borderColor: theme.color.secondary }} />
								<Box pt={15}>
									{!matches && (
										<Box
											className={classes.wrapImg}
											my={5}
											style={{
												transform: "rotate(3deg)",
											}}
										>
											<img src={imatges[2].foto} alt="Restaurant" className={classes.img} />
										</Box>
									)}
									<Typography variant="h3" fontSize={30} color={theme.palette.secondary.main} pb={2}>
										Eventos especiales
									</Typography>
									<Divider
										sx={{
											borderColor: theme.color.secondary,
										}}
									/>
									<Box py={3}>
										<Typography py={1} variant="body1" color={theme.color.secondary}>
											En el Restaurante de Insomnia Hotel, tenemos días especiales que realizamos distintas actividades y experiencias.{" "}
										</Typography>
										<Typography py={3} variant="body1" color={theme.color.secondary}>
											Los días que ofrecemos especiales en nuestro restaurante, podéis reservar habitación + cena espectáculo.
										</Typography>
									</Box>
								</Box>

								<Box className={classes.calendari}>
									<ReactDatePicker
										locale={es}
										dateFormat="dd/MM/yyyy"
										renderDayContents={renderDia}
										inline
										minDate={moment().toDate()}
										maxDate={moment().add(2, "months").toDate()}
									/>
									<Box display="flex" alignItems="center" mt={2}>
										<Close
											style={{
												color: theme.color.primary,
												opacity: 0.8,
											}}
										/>
										<Typography fontSize={14}>No hay mesas disponibles</Typography>
									</Box>
									{/* <Typography fontSize={14} mt={2}>
										*El calendario es solo informativo.
										Puede que no haya disponibilidad.
									</Typography> */}
								</Box>
								{!matches && (
									<Box className={classes.wrapImg} my={5} style={{ transform: "rotate(3deg)" }}>
										<img src={imatges[1].foto} alt="Restaurant" className={classes.img} />
									</Box>
								)}
								<Divider
									sx={{
										background: `url(${sanefa})`,
										borderColor: "transparent",
										height: 15,
										backgroundRepeat: "repeat-x",
										marginBottom: 10,
									}}
								/>
								{jocs?.map((joc, index) => (
									<JocItemPublic joc={joc} max={jocs?.length} index={index} />
								))}
								<Box my={2} />
								{!matches && imatges[2] && (
									<Box
										className={classes.wrapImg}
										mt={5}
										style={{
											transform: "rotate(-4deg)",
										}}
									>
										<img src={imatges[3].foto} alt="Restaurant" className={classes.img} />
									</Box>
								)}
							</Grid>
						</Grid>
					</Loading>
				</Container>
			</Box>
		</Page>
	);
}

export default Restaurant;

const useStyles = makeStyles((theme) => ({
	portada: {
		paddingTop: 230,
		height: "100%",
		background: `url(${bg})`,
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		paddingBottom: 230,
		[theme.breakpoints.down("sm")]: {
			paddingTop: 100,
		},
	},
	wrapImg: {
		transform: "rotate(-5deg)",
		transition: "0.2s",
		zIndex: 0,
		"&:hover": {
			transform: "rotate(-5deg)",
		},
		textAlign: "center",
	},
	img: {
		zIndex: 0,
		width: "90%",
		border: "4px solid " + theme.color.secondary,
	},
	calendari: {
		background: `url(${paper})`,
		padding: 40,
		backgroundRepeat: "round",
		display: "flex",
		flexDirection: "column",
		marginBottom: 100,
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			padding: 10,
		},
	},
}));
