import { Add, Edit } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CartaItem from "./CartaItem";

function CartaCategoriaItem({
	item,
	setSelect,
	setOpen,
	setUpdate,
	setOpenCarta,
	setSelectCarta,
}) {
	const classes = useStyles();

	const handleEdit = () => {
		setSelect(item);
		setOpen(true);
	};

	const handleCarta = () => {
		setSelectCarta("");
		setOpenCarta(true);
		setSelect(item);
	};

	return (
		<Grid item md={4}>
			<Box className={classes.item} textAlign="center">
				<Typography variant="h3">{item.nom}</Typography>
				{item?.cartes?.map((carta) => (
					<CartaItem
						carta={carta}
						setOpenCarta={setOpenCarta}
						setSelectCarta={setSelectCarta}
						setUpdate={setUpdate}
					/>
				))}
				<Box>
					<IconButton onClick={handleCarta}>
						<Add />
					</IconButton>
				</Box>
				<Box className={classes.edit}>
					<IconButton onClick={handleEdit}>
						<Edit />
					</IconButton>
				</Box>
			</Box>
		</Grid>
	);
}

export default CartaCategoriaItem;

const useStyles = makeStyles((theme) => ({
	item: {
		backgroundColor: "white",
		padding: 20,
		position: "relative",
	},
	edit: {
		position: "absolute",
		top: 10,
		right: 10,
	},
}));
