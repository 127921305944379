import { Add, Close, Edit } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { deleteElement } from "../../../API/API";

function CartaItem({ carta, setOpenCarta, setSelectCarta, setUpdate }) {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const handleEdit = () => {
		setSelectCarta(carta);
		setOpenCarta(true);
	};

	const handleDelete = async () => {
		const { message } = await deleteElement("carta", carta.id);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setOpenCarta(false);
		setUpdate((prev) => prev + 1);
	};

	return (
		<Box className={classes.item}>
			<Grid container mt={2}>
				<Grid item md={7} textAlign="left">
					<Box p={1}>
						<Typography>{carta.titol}</Typography>
						<Typography variant="caption" color="black">
							{carta.subtitol}
						</Typography>
					</Box>
				</Grid>
				<Grid item md={5}>
					<Box
						p={1}
						display="flex"
						alignItems="center"
						justifyContent={"right"}
					>
						<Typography>{carta.preu}</Typography>
						<IconButton size="small" onClick={handleEdit}>
							<Edit />
						</IconButton>
						<IconButton size="small" onClick={handleDelete}>
							<Close />
						</IconButton>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default CartaItem;

const useStyles = makeStyles((theme) => ({
	item: {
		backgroundColor: theme.color.secondary,
	},
	edit: {
		position: "absolute",
		top: 10,
		right: 10,
	},
}));
