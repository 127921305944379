import React, { useEffect, useState } from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import theme from "../../../theme";
import CustomButton from "../../../components/elements/CustomButton";
import { refund, updateElement } from "../../../API/API";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import CustomTextField from "../../../components/elements/CustomTextField";

function ClientDialogEdit({
	setUpdate,
	open,
	setOpen,
	id,
	label,
	name,
	value,
}) {
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { handleSubmit, register } = useForm();

	const handleClose = () => {
		setOpen(false);
	};

	const editar = async (values) => {
		setLoading(true);
		const { message } = await updateElement("clients", id, values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setUpdate((prev) => prev + 1);
		setOpen(false);
		setLoading(false);
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
					width: 400,
				},
			}}
			maxWidth="lg"
		>
			<DialogTitle
				sx={{ background: theme.color.primary, color: "white" }}
			>
				<Box display="flex" justifyContent={"space-between"}>
					Editar {label}
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box my={3}>
					<form onSubmit={handleSubmit(editar)}>
						<CustomTextField
							register={register}
							name={name}
							label={label}
							defaultValue={value}
						/>
						<Box my={2} />
						<CustomButton
							title={"Editar"}
							fullWidth
							loading={loading}
							type="submit"
						/>
					</form>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

export default ClientDialogEdit;
